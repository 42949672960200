(<template>
  <FullCalendar ref="fullCalendar"
                :options="calendarOptions" />
</template>)

<script>
  import {mapGetters} from 'vuex';
  // can't be imported on the server side
  import FullCalendar from '@fullcalendar/vue';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import interactionPlugin from '@fullcalendar/interaction';

  export default {
    components: {FullCalendar},
    props: {
      events: {
        type: Array,
        default: () => []
      },
      view: {
        type: String,
        default: ''
      },
      activeDate: {
        type: [String, Object],
        default: ''
      },
      columnHeader: {
        type: [Boolean, undefined],
        default: undefined
      },
      selectable: {
        type: [Boolean, undefined],
        default: false
      }
    },
    data() {
      return {
        calendarOptions: {
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          locale: this.$language.current,
          timeZone: this.userServerTimeZoneName,
          events: this.events,
          initialView: this.view,
          initialDate: this.activeDate,
          headerToolbar: '',
          dayHeaders: this.columnHeader != undefined ? this.columnHeader : true,
          height: '100%',
          firstDay: '1',
          nowIndicator: true,
          allDaySlot: false,
          allDayContent: '',
          scrollTime: '07:00:00',
          showNonCurrentDates: false,
          navLinks: true,
          slotEventOverlap: true,
          views: {dayGridMonth: {dayMaxEventRows: 4}},
          moreLinkContent: (args) => { return `+ ${args.num} ${this.$gettext('more')}`; },
          eventTimeFormat: {
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
            omitZeroMinute: false,
            meridiem: false
          },
          slotLabelFormat: {
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
            omitZeroMinute: false,
            meridiem: false
          },
          navLinkDayClick: this.navDateClick,
          selectable: this.selectable,
          selectMirror: true,
          unselectAuto: true,
          selectOverlap: true,
          eventInteractive: true,
          eventClick: this.eventClick,
          select: this.selectPeriod,
          eventDidMount: this.eventDidMount
        }
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userServerTimeZoneName',
        'userUid'
      ]),
    },
    methods: {
      eventClick(info) { this.$emit('event-click', info); },
      navDateClick(date, jsEvent) { this.$emit('nav-date-click', date, jsEvent); },
      selectPeriod(selectionInfo) { this.$emit('period-selected', selectionInfo); },
      eventDidMount(arg) { this.$emit('event-mount', arg); }
    },
    mounted() {
      this.$emit('catch-calendar-instance', this.$refs.fullCalendar.getApi());
    }
  };
</script>
